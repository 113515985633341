import React from "react"
import HeaderHome from './headerHome'
import FooterHome from './footerHome'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Layout({ children }) {
  return (
    <>
      <HeaderHome />
        {children}
      <FooterHome />
      <ToastContainer 
      closeButton={false}/>
    </>
  )
}