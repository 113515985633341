import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const HeaderRight = styled.div`
  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const HeaderHome = () => {
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const authToken = localStorage.getItem("token");
    setLoggedIn(!!authToken);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    // window.location.href = "/";
  };
  const [showModel, setShowMode] = useState(false);

  const handleOpenMenu = () => {
    setShowMobileMenu(!isShowMobileMenu);
  };

  return (
    <>
      <div id="page" className="site">
        <div />
        <div className="loading-spinner">
          <div className="load-1">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <header id="masthead" className="site-header" style={{ maxWidth: '10vh !important' }}>
        <div id="site-header-wrap" className="header-layout1 is-sticky">
          <div id="site-topbar" className="site-topbar">
            <div className="container">
              <div className="inner-container">
                <div className="item-phone">
                  <i className="material zmdi zmdi-smartphone-android" />
                  <a className="info-text" href="tel:8886661234">
                    888 666 1234{" "}
                  </a>
                </div>

                <div className="item-adderess">
                  <i className="fa fa-map" />
                  <span className="info-text">Suite 100 San Francisco</span>
                </div>

                <div className="item-time">
                  <i className="material zmdi zmdi-hourglass-alt" />
                  <span className="info-text">
                    Mon-Sat 8am - 5pm, Sun Closed
                  </span>
                </div>

                <div className="item-socials">
                  <a title="Facebook" href={!isLoggedIn ? "/" : "/child-program"}>
                    <i className="zmdi zmdi-facebook" />
                  </a>{" "}
                  <a title="Twitter" href={!isLoggedIn ? "/" : "/child-program"}>
                    <i className="zmdi zmdi-twitter" />
                  </a>{" "}
                  <a title="Instagram" href={!isLoggedIn ? "/" : "/child-program"}>
                    <i className="zmdi zmdi-instagram" />
                  </a>{" "}
                  <a title="Skype" href={!isLoggedIn ? "/" : "/child-program"}>
                    <i className="zmdi zmdi-skype" />
                  </a>
                </div>
                {/* The commented out block below is a search icon */}
                {/* <div className="site-header-search-mobile">
                  <span className="h-btn-search">
                    <i className="flaticon3 flaticon3-search-icon" />
                  </span>
                </div> */}
              </div>
            </div>
          </div>
          <div id="site-header" className="site-header-main h-fixed">
            <div className="container">
              <div className="row">
                <div className="site-branding site-branding-mobile">
                  <a
                    className="logo-light"
                    // href={!isLoggedIn ? "https://toddlertalk.com/" : "/toddler-talk-plan-tutorial"}
                    href={"https://toddlertalk.com/"}
                    title="Toddler Talk"
                    rel="home"
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/talk-dark-2.png"
                      alt="Toddler Talk"
                      width={215}
                    />
                  </a>
                  <a
                    className="logo-dark"
                    // href={!isLoggedIn ? "/" : "/toddler-talk-plan-tutorial"}
                    href={"https://toddlertalk.com/"}
                    title="Toddler Talk"
                    rel="home"
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/talk-dark-2.png"
                      alt="Toddler Talk"
                      width={215}
                    />
                  </a>
                  <a
                    className="logo-mobile"
                    href={"https://toddlertalk.com/"}
                    // href={!isLoggedIn ? "https://toddlertalk.com/" : "/toddler-talk-plan-tutorial"}
                    title="Toddler Talk"
                    rel="home"
                  >
                    <StaticImage
                      placeholder="none"
                      src="../images/talk-dark-2.png"
                      alt="Toddler Talk"
                      width={215}
                    />
                  </a>{" "}
                </div>
                <div
                  className={`site-navigation ${isShowMobileMenu ? "navigation-open" : ""
                    }`}
                >
                  <nav className="main-navigation">
                    <ul id="mastmenu" className="primary-menu clearfix">
                      <li
                        id="menu-item-1"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1"
                      >
                        <a href="https://toddlertalk.com/about" target="_blank">About</a>
                      </li>
                      <li
                        id="menu-item-2"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2"
                      >
                        <a href="https://toddlertalk.com/contact" target="_blank">Contact Us</a>
                      </li>
                      <li
                        id="menu-item-2"
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2"
                      >
                        <a href="https://toddlertalk.com/toddler-talk-assessment-faq" target="_blank">FAQ</a>
                      </li>
                      {!isLoggedIn ? (
                        <>
                          {/* <li
                            id="menu-item-3"
                            className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-3"
                          >
                            <a href="/quiz-about">Start Today</a>
                          </li> */}
                          <li
                            id="menu-item-3"
                            className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-3"
                          >
                            <a className='nav-yellow-btn' href="/signin">Sign In</a>
                          </li>
                        </>
                      ) : (
                        <>
                          <li
                            id="menu-item-4"
                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4"
                          >
                            <a href="/account-information">Account</a>
                          </li>
                          <li
                            id="menu-item-5"
                            className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-5"
                          >
                            <a href="https://toddlertalk.com/" onClick={handleLogout}>
                              Sign out
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>
                <HeaderRight className="site-header-right">
                  <div id="main-menu-mobile" onClick={handleOpenMenu}>
                    <span className="btn-nav-mobile open-menu">
                      <span></span>
                    </span>
                  </div>
                </HeaderRight>
              </div>
            </div>
          </div>
        </div>
      </header>{" "}
      {/* <div onClick={() => setShowMode(false)} className={showModel ? "cms-modal cms-modal-search open" : "cms-modal cms-modal-search"}>
            <div className="cms-modal-close" onClick={() => setShowMode(false)} >x</div>
            <div className="cms-modal-content">
                <form role="search" method="get" className="search-form-popup" action="http://localhost/wordpress/">
                    <div className="searchform-wrap">
                        <input type="text" placeholder="Type Words Then Enter" id="search" name="s" className="search-field"/>
                        <button type="submit" className="search-submit"><i className="fa fa-search"></i></button>
                    </div>
                </form>
            </div>
        </div> */}
    </>
  );
};
export default HeaderHome;


{/* <a
class="btn-yellow hv-secondary"
href="/signin"
>
<span class="btn-text">
  Sign In{" "}
</span>
</a> */}
