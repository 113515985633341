import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";
import personIcon from "../images/person-icon-nav.png";
import logoIcon from "../images/logo.png";

const FooterHome = () => {
  return (
    <>
      <footer id="colophon" className="site-footer-custom  fixed-bottom-footer">
        <div className="footer-custom-inner">
          <div className="">
            <div className="row">
              <div className="col-12">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="30"
                  className="elementor elementor-30"
                  data-elementor-settings="[]"
                >
                  <div className="elementor-section-wrap">
                    {/* <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-a17f965 elementor-section-stretched bg-footer-custom elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="a17f965"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-default ">
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-f321375"
                          data-id="f321375"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4896a0e elementor-widget elementor-widget-cms_image_single"
                              data-id="4896a0e"
                              data-element_type="widget"
                              data-widget_type="cms_image_single.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-image-single animation-none ">
                                  <div className="cms-img-box">
                                    <a href="/">
                                      {" "}
                                      <StaticImage
                                        placeholder="none"
                                        src="../images/talk-light.png"
                                        className="attachment-full size-full"
                                        alt=""
                                        loading="lazy"
                                      />{" "}
                                    </a>{" "}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-3a12c04 elementor-widget elementor-widget-cms_text_editor"
                              data-id="3a12c04"
                              data-element_type="widget"
                              data-widget_type="cms_text_editor.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-text-editor elementor-clearfix ">
                                  <p>
                                    Uncover your power. You can be the hero of
                                    your child’s communication journey.
                                  </p>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-be68c21"
                          data-id="be68c21"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-1d2a5ba elementor-widget elementor-widget-cms_contact_info"
                              data-id="1d2a5ba"
                              data-element_type="widget"
                              data-widget_type="cms_contact_info.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-contact-info3 ">
                                  <h3 className="entry-title">
                                  <a href ="/contact-us" style={{color:'white'}}>
                                    Contact Us
                                    </a></h3>
                                  <ul className="list-li">
                                    <li>
                                      <span className="item--icon">
                                        <i
                                          aria-hidden="true"
                                          className="material zmdi zmdi-email"
                                        ></i>{" "}
                                      </span>
                                      info@toddlertalk.com
                                      </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-04f7b9a"
                          data-id="04f7b9a"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-764a111 elementor-widget elementor-widget-cms_heading"
                              data-id="764a111"
                              data-element_type="widget"
                              data-widget_type="cms_heading.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-heading-wrapper cms-heading-layout1 ">
                                  <h3 className="custom-heading">
                                    <span>Our Program</span>
                                  </h3>
                                </div>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-de7b6b0 elementor-widget elementor-widget-cms_navigation_menu"
                              data-id="de7b6b0"
                              data-element_type="widget"
                              data-widget_type="cms_navigation_menu.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-navigation-wrap style2 ">
                                  <div
                                    id="cms_navigation_menu-de7b6b0"
                                    className="cms-navigation-menu1 style2"
                                  >
                                    <div className="menu-class-single-menu-container">
                                      <ul
                                        id="menu-class-single-menu"
                                        className="menu"
                                      >
                                        <li
                                          id="menu-item-3168"
                                          className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168"
                                        >
                                          <a href="/">Expression</a>
                                        </li>
                                        <li
                                          id="menu-item-3170"
                                          className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3170"
                                        >
                                          <a href="/">Comprehension</a>
                                        </li>
                                        <li
                                          id="menu-item-3171"
                                          className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3171"
                                        >
                                          <a href="/">Speech Sounds</a>
                                        </li>
                                        <li
                                          id="menu-item-3172"
                                          className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3172"
                                        >
                                          <a href="/">Readiness to Learn</a>
                                        </li>
                                      </ul>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-4290735"
                          data-id="4290735"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-1311044 elementor-widget elementor-widget-cms_heading"
                              data-id="1311044"
                              data-element_type="widget"
                              data-widget_type="cms_heading.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-heading-wrapper cms-heading-layout1 ">
                                  <h3 className="custom-heading">
                                    <span>Newsletter</span>
                                  </h3>

                                  <div className="custom-heading-description">
                                    Subscribe to our Newsletter right now to be
                                    updated.{" "}
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-1bfd487 elementor-widget elementor-widget-cms_newsletter"
                              data-id="1bfd487"
                              data-element_type="widget"
                              data-widget_type="cms_newsletter.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="cms_newsletter-1bfd487"
                                  className="cms-newsletter style2"
                                >
                                  <form
                                    className="tnp-form"
                                    action="http://localhost/wordpress/?na=s"
                                    method="post"
                                    // onSubmit="return newsletter_check(this)"
                                  >
                                    <input
                                      type="hidden"
                                      name="nr"
                                      value="widget-minimal"
                                    />
                                    <div className="tnp-field tnp-field-email">
                                      <input
                                        className="tnp-email"
                                        type="email"
                                        required
                                        name="ne"
                                        // value=""
                                        placeholder=" Email"
                                        defaultValue=""
                                      />
                                    </div>
                                    <div className="tnp-field tnp-field-button">
                                      <input
                                        className="tnp-button"
                                        type="submit"
                                        value="Subscribe"
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section> */}
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-13939f2 elementor-section-stretched elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="13939f2"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classic"}'
                    >
                      <div className="elementor-container elementor-column-gap-default ">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b5a94c1"
                          data-id="b5a94c1"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9b61f8c elementor-widget elementor-widget-cms_text_editor"
                              data-id="9b61f8c"
                              data-element_type="widget"
                              data-widget_type="cms_text_editor.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-text-editor elementor-clearfix ">
                                  <p>
                                    © 2021 All Rights Reserved. With love by{" "}
                                    <a href="https://toddlertalk.com/" target="_blank" rel="noopener">
                                      Toddler Talk
                                    </a>
                                    .
                                  </p>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-da52401"
                          data-id="da52401"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-45fe0af elementor-widget elementor-widget-cms_navigation_menu"
                              data-id="45fe0af"
                              data-element_type="widget"
                              data-widget_type="cms_navigation_menu.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="cms-navigation-wrap style1 ">
                                  <div
                                    id="cms_navigation_menu-45fe0af"
                                    className="cms-navigation-menu1 style1"
                                  >
                                    <div className="menu-class-single-menu-container">
                                      <ul
                                        id="menu-class-single-menu-1"
                                        className="menu"
                                      >
                                        <li className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168">
                                          <a href="https://toddlertalk.com/terms-and-conditions" target="_blank">
                                            Terms & Conditions
                                          </a>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168">
                                          <a href="https://toddlertalk.com/privacy-policy" target="_blank">Privacy Policy</a>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168">
                                          <a href="https://toddlertalk.com/toddler-talk-assessment-faq" target="_blank">FAQ</a>{" "}
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168">
                                          <a href="https://toddlertalk.com/contact" target="_blank">
                                            Contact Us
                                          </a>
                                        </li>
                                        <li className="menu-item menu-item-type-post_type menu-item-object-classes menu-item-3168">
                                          <a href="https://toddlertalk.com/about" target="_blank">About Us</a>
                                        </li>
                                      </ul>
                                    </div>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="cms-modal cms-modal-search">
        <div className="cms-modal-close">x</div>
        <div className="cms-modal-content">
          <form
            role="search"
            method="get"
            className="search-form-popup"
            action="http://localhost/wordpress/"
          >
            <div className="searchform-wrap">
              <input
                type="text"
                placeholder="Type Words Then Enter"
                id="search"
                name="s"
                className="search-field"
              />
              <button type="submit" className="search-submit">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
};
export default FooterHome;
